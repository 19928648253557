import './App.css';
import { Component } from 'react';
import Home from './Home';
import Page from './Page';
import logo from './media/logo.png';

class App extends Component {
  
  intervalID = 0;

  constructor(props){
    super(props);
    
    
    var pageCode = window.location.href.replace(window.location.origin, "");
    pageCode = pageCode.replace("/", "");   
    var showHome = false;

    if(pageCode == ''){
      pageCode = '';
    }
    
    this.state = {showHome: false, showPage: false, pageCode: pageCode, isReady: false };
        
  }

  componentDidMount () {

    var sessiontoken = localStorage.getItem('session_token');
     
    if(sessiontoken === null){     
      this.createToken();
      return;
    }else{
      this.checkToken(sessiontoken);
      return;
    }
     
  }

  firstLoad = () => {
    if(this.state.pageCode == ''){
      //show home
      this.setState({ showPage: false, showHome: true });
    }else{
      
      this.setState({ showPage: true, showHome: false });
      
    }
   // this.getProfileInfo();
  }

checkToken = (token) => {
    
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: token
      })
      };
    
      const apiUrl = 'https://api.otokudining.com/public/v1/hello/check';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      console.log(r);
      this.setState({  isReady: true });
      this.firstLoad();
      
    }).catch(e => {
        localStorage.removeItem('session_token');
        window.location.reload();
    });

  }

  createToken = () => {
   
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
      };
    
      const apiUrl = 'https://api.otokudining.com/public/v1/hello/client';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      localStorage.setItem('session_token', r.token);
      this.checkToken(r.token);
    }).catch(e => {
      
       //localStorage.removeItem('session_token');
       // window.location.reload();
    });

  }

  showHome = () => {
    window.location.replace("https://www.otokudining.com");
  }
  

  render(){
    
    return (  
    <div className='App' style={{ backgroundColor: 'rgb(255,255,255)'}}>
      
      <div style={{ top: 0, width: '100%', height: '50px', position: 'sticky', backgroundColor: 'white', zIndex: 1000 }}>
   <div style={{ width: '100%', top: '49px', height: '1px', backgroundColor: '#F2F1F9', position: 'absolute' }}></div>
  
  

   <div style={{ display: "flex", flexDirection: "row", justifyContent: "center",
    alignItems: "stretch",
    flexWrap: 'wrap',
    flexShrink: 1}}>
        <div onClick={this.showHome} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '600', verticalAlign: 'center', textAlign: 'center', margin: '2.5px', cursor: 'pointer'}}>
        <img src={logo} width="120px" style={{ verticalAlign: 'center', marginRight: '15px' }} />
          </div>
        </div>
       
   </div>

      {this.state.showHome && 
      <Home />
      }

      { (this.state.isReady && this.state.showPage) &&
       <Page code={this.state.pageCode} />
      } 

      

    </div>
  );
    }
}

export default App;
