import React, { Component } from 'react'
import pagenotfound from './media/pagenotfound.png';
import covermask from './media/maskregion.png';
import maps from './media/maps.png';
import MenuList from './MenuList';
import Coupons from './Coupons';
import Bags from './Bags';
import Contacts from './Contacts';
export class Page extends Component {

    constructor(props){
        super(props);
       

        this.state = { code: props.code, pageInfo: null, selectedSection: -1, sectionInfo: null, notExists: false, coupons: [], bags: [] };
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.code !== props.code) {
          return {
            code: props.code,
            
          }
        }

        return null;
    }

    componentDidMount () {
    
        this.getPageInfo();
    }
  
    getPageInfo = () => {
        var sessiontoken = localStorage.getItem('session_token');

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              code: this.state.code
            })
            };
          
            const apiUrl = 'https://api.otokudining.com/public/v1/page/code';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {           
            console.log(r);
            if(r.info == null){
                this.setState({ notExists: true });
            }else{
                this.setState({ coupons: r.coupons, bags: r.bags, pageInfo: r.info, menu: r.menu, eventsInfo: r.events, notExists: false, selectedSection: 0  });
               
            }
            
          }).catch(e => {
              this.setState({ notExists: true });
          });
    }

    selectSection = (id_section) => {
        this.setState({ selectedSection: id_section });
        
    }


    openOnMap = () => {
      let newLocation = "https://www.google.com/maps/place/"+this.state.pageInfo.address;
      window.open(newLocation, "_blank");
    }

    buyCoupon = (coupon_id, quantity) => {

    }
  

    render() {
        return (
            <div>
            
              {this.state.pageInfo != null &&
              <div style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                <div   style={{width: '100%', maxWidth: '500px', position: 'relative'}}>
                <div style={{width: '100%', height: '300px', position: 'relative',  }}>
                        <div style={{width: '100%', height: 300, left: 0, top: 0}}>
                 <img src={this.state.pageInfo.cover_image} width="100%" height="300" style={{ objectFit: 'cover'}} />
                 </div>
                 <div style={{width: '100%', height: 300, left: 0, top: 0, position: 'absolute'}}>
                 <img src={covermask} width="100%" height="300"  />

                 <div style={{width: '100%', marginLeft: '10px', marginRight: '10px', paddingBottom: '10px',  height: 30, left: 0, top: 265, textSizeAdjust: 'true', position: 'absolute', textAlign: 'left', color: 'white', fontSize: '18px', fontWeight: '800', wordWrap: 'break-word'}}>{this.state.pageInfo.name}</div>
                 <div style={{width: '100%', marginLeft: '10px', marginRight: '10px', paddingBottom: '10px',  height: 30, left: 0, top: 240, textSizeAdjust: 'true', position: 'absolute', textAlign: 'left', color: 'white', fontSize: '12px', fontWeight: '400', wordWrap: 'break-word'}}>{this.state.pageInfo.subtitle}</div>
        
                 </div>

                
                </div>
                     
               
               

                <div  style={{ alignContent: 'center', alignItems: 'center', marginTop: '15px',   marginLeft: '0px', marginRight: '5px', display: 'flex'}}>
                <div style={{ marginLeft: '10px', color: 'rgb(100,100,100)', textAlign: 'left', fontWeight: '400', fontSize: '14px' }}>{this.state.pageInfo.address}</div>  
                <img onClick={this.openOnMap} style={{ marginLeft: '15px', cursor: 'pointer' }} width="40" src={maps} />
                </div>
              
                </div>
                
              </div>
              }

              <div style={{ width: '100%', marginTop: '15px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
  
              {this.state.pageInfo != null &&
              <div style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex' }}>
                <div   style={{width: '100%', maxWidth: '500px', position: 'relative'}}>
                  <MenuList select={this.selectSection} />
                </div>
              </div>
              }

              {this.state.selectedSection == 0 &&
              <Coupons buyCoupon={this.buyCoupon} info={this.state.coupons} />}

              {this.state.selectedSection == 1 &&
              <Bags buyCoupon={this.buyCoupon} info={this.state.bags} />}

              {this.state.selectedSection == 2 &&
              <Contacts info={this.state.pageInfo} />}

            
              {this.state.notExists && 
              <div  style={{ width: '100%', marginTop: '20px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh', display: 'flex'}}>
              <img src={pagenotfound} width="40px" height="40px" style={{ objectFit: 'cover', borderRadius: '25px'}} />
              <div style={{ marginLeft: '20px', color: 'rgb(50,50,50)', fontWeight: '600', fontSize: '18px' }}>Page not found</div>  
              </div> 
              }

              

            </div>
        )
    }
}

export default Page
