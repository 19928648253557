import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';

export class Contacts extends Component {

    constructor(props){
        super(props);
        this.state = {  info: props.info };
    }

    componentDidMount () {
    
       
    }
  
   

    render() {
        return (
            <div>
            

            <div style={{ marginLeft: '10px', marginTop: '5px',   textAlign: 'center', color: 'rgb(100,100,100)', fontSize: 16,  fontWeight: '800', wordWrap: 'break-word'}}>{this.state.info.name}</div>
            <br />
            <div style={{ marginLeft: '10px', marginTop: '5px',   textAlign: 'center', color: 'rgb(100,100,100)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}><span style={{ fontWeight: '800' }}>Indirizzo:</span> {this.state.info.address}</div>
            <div style={{ marginLeft: '10px', marginTop: '5px',   textAlign: 'center', color: 'rgb(100,100,100)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}><span style={{ fontWeight: '800' }}>Telefono:</span> {this.state.info.phone_number}</div>
            <div style={{ marginLeft: '10px', marginTop: '5px',   textAlign: 'center', color: 'rgb(100,100,100)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}><span style={{ fontWeight: '800' }}>Sito web:</span> {this.state.info.web_site}</div>
            <br />
            </div>
        )
    }
}

export default Contacts
