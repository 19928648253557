import React, { Component } from 'react'

export class MenuList extends Component {

    constructor(props){
        super(props);
        this.state = {  selectedSection: 0 };
    }

   

    componentDidMount () {
    
       
    }
  

    selectedSection = (index) => {
        this.setState({ selectedSection: index });
     
        this.props.select(index);
        
        
    }
   

    render() {
        return (
            <div>
              
              <div style={{  justifyContent: 'center', marginTop: '15px', position: 'relative', backgroundColor: 'rgb(255,255,255)', display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', flexDirection: 'row',scrollBehavior: 'smooth'}}>

              <div onClick={() => this.selectedSection(0)} style={{ cursor: 'pointer', marginLeft: '0px', height: '40px', minWidth: '80px', flexWrap: 'nowrap'}}>
           {this.state.selectedSection == (0) &&
               <div style={{ }}><div style={{ fontWeight: '500', fontSize: '14px', color: '#F49702' }}>Coupon</div>  
               <div style={{width: '100%', height: 1, marginTop: '10px', backgroundColor: '#F49702'}}>
               </div></div>
           }
           {this.state.selectedSection != (0) &&
                <div style={{ }}><div style={{ fontWeight: '500', color: 'rgb(150,150,150)', fontSize: '14px' }}>Coupon</div>  
                <div style={{width: '100%', height: 1, marginTop: '10px', backgroundColor: 'rgba(200,200,200,1)'}}>
                </div></div>
           }
           
            </div>

            <div onClick={() => this.selectedSection(1)} style={{ cursor: 'pointer', marginLeft: '0px', height: '40px', minWidth: '80px', flexWrap: 'nowrap'}}>
           {this.state.selectedSection == (1) &&
               <div style={{ }}><div style={{ fontWeight: '500', fontSize: '14px', color: '#F49702' }}>Bags</div>  
               <div style={{width: '100%', height: 1, marginTop: '10px', backgroundColor: '#F49702'}}>
               </div></div>
           }
           {this.state.selectedSection != (1) &&
                <div style={{ }}><div style={{ fontWeight: '500', color: 'rgb(150,150,150)', fontSize: '14px' }}>Bags</div>  
                <div style={{width: '100%', height: 1, marginTop: '10px', backgroundColor: 'rgba(200,200,200,1)'}}>
                </div></div>
           }
           
            </div>

            <div onClick={() => this.selectedSection(2)} style={{ cursor: 'pointer', marginLeft: '0px', height: '40px', minWidth: '80px', flexWrap: 'nowrap'}}>
           {this.state.selectedSection == (2) &&
               <div style={{ }}><div style={{ fontWeight: '500', fontSize: '14px', color: '#F49702' }}>Contacts</div>  
               <div style={{width: '100%', height: 1, marginTop: '10px', backgroundColor: '#F49702'}}>
               </div></div>
           }
           {this.state.selectedSection != (2) &&
                <div style={{ }}><div style={{ fontWeight: '500', color: 'rgb(150,150,150)', fontSize: '14px' }}>Contacts</div>  
                <div style={{width: '100%', height: 1, marginTop: '10px', backgroundColor: 'rgba(200,200,200,1)'}}>
                </div></div>
           }
           
            </div>
                
                </div> 

            </div>
        )
    }
}

export default MenuList
