import React, { Component } from 'react'
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';

export class Bags extends Component {

    constructor(props){
        super(props);
        this.state = {  coupons: props.info, showCouponInfo: false, currentCoupon: null, currentQuantity: 1 };
    }

   

    componentDidMount () {
    
       
    }
  
    closeModals = () => {
        this.setState({ showCouponInfo: false, currentCoupon: null});
    }

    openProduct = (index) => {
        var currentCoupon = this.state.coupons[index];
        this.setState({ showCouponInfo: true, currentCoupon: currentCoupon, currentQuantity: 1 });
    }
   
    addQuantity = () => {
        this.setState({ currentQuantity: this.state.currentQuantity+1});
      }

      removeQuantity = () => {
        if(this.state.currentQuantity>1){
          this.setState({ currentQuantity: this.state.currentQuantity-1});
        }
      }

      buyCoupon = () => {
        this.props.buyCoupon(this.state.currentCoupon.id, this.state.currentQuantity);
      }

    render() {
        return (
            <div>
            

            <div style={{ marginLeft: '10px', marginTop: '5px',   textAlign: 'center', color: 'rgb(100,100,100)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>Evita Che Dell'Ottimo Cibo Vada Sprecato</div>
            <div style={{ marginLeft: '10px', marginTop: '5px',   textAlign: 'center', color: 'rgb(100,100,100)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>Gusta buon cibo a metà prezzo o meno, aiuta l'ambiente e riduci gli sprechi</div>
            <br />
              <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  borderRadius: '8px',
  margin: '0px',
  flexShrink: 1,}}>
  {
        this.state.coupons.map((product, i) =>
<div key={i} onClick={() => this.openProduct(i)}  style={{ cursor: 'pointer', borderRadius: '8px', width: '380px', minHeight: '100px', margin: '10px', backgroundColor: 'white', position: 'relative' }}>
        <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
<div style={{ width: 100, height: 100, left: 0, top: 0}}>
 <img src={product.cover_image} width="100px" height="100px" style={{ objectFit: 'cover' }} />
 </div>
 <div style={{}}>
 <div style={{width: 180,  marginLeft: '10px', marginTop: '5px',   textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '800', wordWrap: 'break-word'}}>{product.name}</div>
 <div style={{width: 180,  marginLeft: '10px', marginTop: '5px',   textAlign: 'left', color: 'rgb(100,100,100)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{product.description}</div>
 <div style={{width: 180, marginLeft: '10px', marginTop: '10px',  textAlign: 'left', color: '#F49702', fontSize: 16,  fontWeight: '800', wordWrap: 'break-word'}}>{product.price}¥</div>

</div>
<div style={{ width: 80, backgroundColor: 'white' }}>
                <div style={{ marginTop: '10px', marginLeft: '5px', width: 80, display: 'flex', flexDirection: 'row'}}>
                <div style={{ marginLeft: '10px', borderRadius: '8px', marginRight: '20px', padding: '10px', width: '70px', color: 'white', backgroundColor: '#F49702', fontSize: '12px', fontWeight: '600'}}>reserve</div>
                </div> 
               
                </div> 
 </div>
            
 <div style={{ width: '100%', marginTop: '25px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
</div>
        )
 }
 </div>


 <Drawer open={this.state.showCouponInfo}
                    onRequestClose={this.closeModals}
                    modalElementClass="modal">
            <div>

            {this.state.currentCoupon != null &&
            <div style={{ justifyContent: 'center', alignContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
            <div style={{ cursor: 'pointer', margin: 'auto', marginTop: '20px', width: '90%', borderRadius: '15px', backgroundColor: 'white', minHeight: '50px'}}>
           
           <div style={{ fontWeight: '600', width: '70%', textAlign: 'left', marginLeft: '15px', marginTop: '10px', fontSize: '16px', fontWeight: '800', color: 'rgb(50,50,50)' }}>{this.state.currentCoupon.name}</div>  
           <div style={{ fontWeight: '600', marginLeft: '69%', width: '30%', minHeight: '30px', textAlign: 'right', fontSize: '16px', fontWeight: '800', color: '#F49702'  }}>{this.state.currentCoupon.price}
            <span>¥</span>
           </div> 

           {this.state.currentCoupon.description != "" &&
           <div style={{ fontWeight: '400', width: '70%', marginBottom: '10px', marginTop: '-20px', textAlign: 'left', marginLeft: '15px',fontWeight: '400', fontSize: '12px', color: 'rgb(100,100,100)' }}>{this.state.currentCoupon.description}</div>  
           }
           </div>
           
            <div style={{ width: '100%', marginTop: '10px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
           <div>
           <div>
                <div style={{ marginTop: '25px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ userSelect: 'none', color: 'rgb(100,100,100)', fontSize: '34px', cursor: 'pointer'}} onClick={this.removeQuantity}>-</div>
                <div style={{ userSelect: 'none', marginLeft: '30px', marginRight: '30px', color: 'black', fontSize: '35px'}}>{this.state.currentQuantity}</div>
                <div style={{ userSelect: 'none', color: 'rgb(100,100,100)', fontSize: '34px', cursor: 'pointer'}} onClick={this.addQuantity}>+</div>
                </div>
            </div>
            <br/>
            <div style={{ width: '100%', marginTop: '10px', height: '1px', backgroundColor: '#F2F1F9' }}></div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px'}}>
            <div>
            <div style={{ fontWeight: '400', width: '30%', margin: 'auto', marginTop: '20px', minHeight: '30px', textAlign: 'center', fontSize: '14px', color: 'rgb(50,50,50)'  }}>Total
            </div> 
            <div style={{ fontWeight: '600', width: '30%', margin: 'auto', marginTop: '0px', minHeight: '30px', textAlign: 'center', fontSize: '20px', color: 'rgb(50,50,50)'  }}>{this.state.currentCoupon.price*this.state.currentQuantity}
            <span>¥</span>
           </div> 
           </div>
           <div>
            <br />
           <div onClick={this.buyCoupon} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px', fontWeight: '600', verticalAlign: 'middle', margin: '7.5px', 
            height: '50px', borderRadius: '8px', color: 'white', backgroundColor: '#F49702', cursor: 'pointer', width: '150px'}}>
          Continue</div>
          </div></div>

          </div>


            </div>
            }
                             
           
            </div>
                 
            </Drawer>

            </div>
        )
    }
}

export default Bags
